@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap"');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ReactVirtualized__List {
  height: auto !important;
}

.react-datepicker-wrapper input {
  padding: 8px 12px;
  font-size: 15px;
  text-align: center;
  width: 130px;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  cursor: pointer;
}

.rdw-link-modal {
  height: 250px !important;
}

/* react-draft-wysiwyg position image on center */
.rdw-image-imagewrapper {
  display: flex;
  justify-content: center;
}

div.rdw-image-modal,
div.rdw-emoji-modal,
div.rdw-embedded-modal,
div.rdw-colorpicker-modal,
div.rdw-link-modal,
div.rdw-attachment-modal {
  top: unset;
  bottom: 35px;
}

/* Always show scrollbar */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
